import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

const BuyersPage = () => (
  <Layout>
    <SEO title="Buyers" />
    <Bradcam siteTitle="Buyers"></Bradcam>

    <div class="about_mission service-section">
        <div class="container">
            <div class="align-items-center">
                <div class="about_thumb">
                    <img src="/img/service/buyer.png" alt="" />
                </div>
                <div>
              
<p><b>Meet with a real estate professional:</b> Discuss the type of home you're looking for, including style, price, and location.</p>
<p><b>The Buyer's Advantage:</b> As the home buyer, your agent's commission is paid by the seller of the home in almost all circumstances. This means your representation costs you nothing!</p>
<p><b>Get Pre-Approved:</b> You will need pay stubs, W2s, and bank statements. Knowing what you can afford is critical to a successful home shopping experience</p>
<p><b>Search for Homes:</b> The fun part! Your agent will schedule showings and help you find the perfect home. </p>
<p><b>Advanced Search:</b> Not all real estate websites are the same. Your real estate professional has tools and systems to ensure you see every available home that meets your criteria. </p>
<p><b>Make an Offer:</b> Your agent will prepare your offer based on the price and terms you choose. </p>
<p><b>Negotiation and Contract:</b> It may take a few tries to get it just right, but hang in there. You're on your way!</p>
<p><b>The Contract:</b> In most cases the contract provides you with a timeline to obtain financing as well as time to inspect the physical condition of the home. Your real estate professional will inform you of all your rights and responsibilities related to the contract.</p>
<p><b>Under Contract or In Escrow:</b> You and the seller have agreed to the price and terms. The home is effectively held for you until closing. </p>
<p><b>Final Details:</b> Perform due diligence, order the appraisal, conduct an inspection, and review terms with the lender.</p>
<p><b>Preparing for Closing:</b> You will be finalizing your loan, reviewing documents, and discussing the findings from the inspection. Your agent will manage this entire process for you. </p>
<p><b>Closing:</b> This is the transfer of funds and ownership. A title company or an attorney typically acts as an independent third party to facilitate the closing. </p>

                </div>
              

            </div>
        </div>
    </div>
  </Layout>
)

export default BuyersPage
